
$fa-font-path: "/site/assets/dist/fonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

/* poppins-200 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('/site/assets/fonts/poppins-v20-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/site/assets/fonts/poppins-v20-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/site/assets/fonts/poppins-v20-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-200.woff') format('woff'), /* Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/site/assets/fonts/poppins-v20-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-200italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: url('/site/assets/fonts/poppins-v20-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/site/assets/fonts/poppins-v20-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/site/assets/fonts/poppins-v20-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-200italic.woff') format('woff'), /* Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/site/assets/fonts/poppins-v20-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/site/assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/site/assets/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/site/assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/site/assets/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('/site/assets/fonts/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/site/assets/fonts/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/site/assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/site/assets/fonts/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('/site/assets/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/site/assets/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/site/assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/site/assets/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('/site/assets/fonts/poppins-v20-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/site/assets/fonts/poppins-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/site/assets/fonts/poppins-v20-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('/site/assets/fonts/poppins-v20-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/site/assets/fonts/poppins-v20-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }